import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../Styles/BlogPage.css"
// import tips101 from "../img/Assets/Blog_101tips.jpeg";

const DevelopmentTraining = () => {
    return ( 
        <>
        <div className="blogread-bg">
        <Navbar/>
        <div className="container font-poppins blog-box-position">
            <div className="container text-white blog-box">
                <ul className="list-unstyled d-flex mt-5 mb-3">
                    <li>Author: Apoorva Raj</li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>Jan 6, 2020 </li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>1 min read</li>
                </ul>

                <div className="blog-heading mt-3 mb-3">
                101 tips for starting athletic development training
                </div>

                <div className="mt-4 mb-4">
                Starting athletic development training can be overwhelming, but by following these 101 tips, you can set yourself up for success. It's important to set clear and specific goals, create a plan and stick to it, and include a variety of exercises such as strength training, cardio, and flexibility work. Always focus on proper form and technique, and incorporate progressive overload to gradually increase the difficulty of your workouts. Prioritize recovery and rest, and keep track of your progress to adjust your plan as needed. Fuel your body with nutrient-dense foods and hydrate properly. Seek out professional guidance from a coach or trainer, and listen to your body to prevent burnout or injury. Incorporate plyometric, compound, isolation, core, and sport-specific exercises to improve power, explosiveness, stability, and performance. Incorporate active recovery methods and meditative practices to aid in recovery and mental focus. Remember to include mental visualization, aerobic, eccentric, and isometric exercises to improve overall athleticism.
                </div>

                <div className="d-flex justify-content-center mt-5 mb-5">
                    <img className="blog-img" src="https://ik.imagekit.io/deltafit/Assets/Blog_101tips.jpeg" alt="101tips"/>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
        </>
     );
}
 
export default DevelopmentTraining;