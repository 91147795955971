import Navbar from "../components/Navbar";
import Footer from "../components/Footer";



const About=()=>{
    return(
        <>
            <Navbar/>
            <h1>THIS IS About PAGE</h1>
            <Footer/>
        </>
    )
}

export default About;