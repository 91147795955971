import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import React from "react";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("Form sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, phone, message } = data;
    // if not valid 
    // return
    try {
      const templateParams = {
        name,
        email,
        phone,
        message,
      };

      await emailjs.send(
        // "service_bikytoi",
        process.env.REACT_APP_SERVICE_ID,
        // "template_dv719mg",
        process.env.REACT_APP_TEMPLATE_ID,
        // templateParams,
        templateParams,
        // "tnIgJ3CXMoMvVm7Jg"
        process.env.REACT_APP_USER_ID
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="bg-contact-us">
      <div className="container-contact-us">
        <div className="d-flex align-items-center" style={{maxWidth:"100%"}}>
          {/* <div className=''> */}
          <div className="col-lg-6 display-non-mob-view" style={{ paddingTop: "8em" }}>
            <span className="get-in-touch">GET IN TOUCH</span>
            <br />
            <span className="with-us">With Us.</span>
            <div className="row pt-5">
              <div className="col-lg-6">
                <img src="https://ik.imagekit.io/deltafit/Assets/contactimg1.png" alt="running-1" />
              </div>
              <div className="col-lg-6">
                <img src="https://ik.imagekit.io/deltafit/Assets/contactimg2.png" alt="running-1" />
              </div>
            </div>
          </div>
          {/* </div> */}

          <div
            className="col-lg-6 display-non-mob-contact d-flex align-items-center justify-content-center"
            style={{ paddingTop: "84px" }}
          >
            <div className="contact-us-card">
              <div className="text-center pt-5">
                <span className="have-a-question">
                  Contact Us
                  <br />
                </span>
                <span className="feel-free-to-contact-us">
                  {/* Feel free to contact us. */}
                </span>
              </div>
              <div className="container d-flex justify-content-center contact-form-input-fields">
                <div className="ContactForm">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 text-center">
                        <div className="contactForm">
                          <form
                            id="contact-form"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                          >
                            {/* Row 1 of form */}
                            <div className="row formRow">
                              <div className="col-6" style={{paddingRight:"5px"}}>
                                <input
                                  type="text"
                                  name="name"
                                  {...register("name", {
                                    required: {
                                      value: true,
                                      message: "Please enter your name*",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Please use 30 characters or less",
                                    },
                                  })}
                                  className="form-control formInput input-contact-us"
                                  placeholder="Name"
                                ></input>
                                {errors.name && (
                                  <span className="errorMessage">
                                    {errors.name.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-6" style={{paddingLeft:"5px"}}>
                                <input
                                  type="number"
                                  name="phone"
                                  {...register("phone", {
                                    required: {
                                      value: true,
                                      message: "Please enter a valid Number*",
                                    },
                                    maxLength: {
                                      value: 75,
                                      message:
                                        "phone cannot exceed 20 characters",
                                    },
                                  })}
                                  className="form-control formInput input-contact-us"
                                  placeholder="Phone Number"
                                ></input>
                                {errors.phone && (
                                  <span className="errorMessage">
                                    {errors.phone.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* Row 2 of form */}
                            <div className="row formRow">
                              <div className="col">
                                <input
                                  type="email"
                                  name="email"
                                  {...register("email", {
                                    required: true,
                                    pattern:
                                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  })}
                                  className="form-control formInput input-contact-us"
                                  placeholder="Email address"
                                ></input>
                                {errors.email && (
                                  <span className="errorMessage">
                                    Please enter a valid email address*
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* Row 3 of form */}
                            <div className="row formRow">
                              <div className="col">
                                <textarea
                                  rows={5}
                                  name="message"
                                  {...register("message", {
                                    required: true,
                                  })}
                                  className="form-control formInput input-contact-us"
                                  placeholder="Message"
                                ></textarea>
                                {errors.message && (
                                  <span className="errorMessage">
                                    Please enter a message*
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-item-center">
                              <button
                                className="submit-btn-contact-us"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>

                          </form>
                            <div style={{ marginTop: "40px" }} className="d-flex">
                              {/* <div className="contact-us-address">
                                Delta Fitness Training Centre, Block -S Sector
                                -75, BPTP Greater, Near Discovery Society
                                Greater Faridabad.
                              </div> */}
                            </div>

                            
                        </div>
                        
                        
                      </div>
                        <ToastContainer />
                        
                        {/* <div className="bg-contact-us-position">    
                            <div className="bg-contact-us-font-style">
                                CONTACT US
                            </div>
                        </div> */}



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="bg-contact-us-position">    
        <span className="bg-contact-us-font-style">REACH US</span> 
        
        </div>
      
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
