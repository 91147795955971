import Navbar from "../components/Navbar";
// import Images from "../components/GalleryPageMedia";
import Footer from "../components/Footer";
// import "../Styles/GalleryPage.css"


const Services=()=>{
    return(
        <>
            <Navbar/>
            <div className="container">
            {/* <div className="row text-center align-items-center justify-content-center"> 
            <h1>At Delta fitness we just don't make atheletes,<br/>we make families.</h1>
            </div>
            <Images/> */}
            <div className="row d-flex align-items-center justify-content-center" style={{height:"80vh"}}>
            {/* <div className="col-12"> */}
            <img className="pad-on-mobview" src="https://ik.imagekit.io/deltafit/Assets/ComingSoon.jpg" style={{width:"820px"}} alt="under-construction"/>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center sport-category-h3-blue pad-on-mobview2" style={{fontSize:"48px"}}>Coming Soon.</div> */}
            </div>
            {/* </div> */}
            <Footer/>
        </>
    )
}

export default Services;