import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../Styles/BlogPage.css"

const DevelopmentEndurance = () => {
    return ( 
        <>
        <div className="blogread-bg">
        <Navbar/>
        <div className="container font-poppins blog-box-position">
            <div className="container text-white blog-box">
                <ul className="list-unstyled d-flex mt-5 mb-3">
                    <li>Author: Apoorva Raj</li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>Jan 6, 2020 </li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>1 min read</li>
                </ul>

                <div className="blog-heading mt-3 mb-3">
                Developing Endurance
                </div>

                <div className="mt-4 mb-4">
                Developing endurance is key to improving your overall fitness and performance. To get started, setting a goal for your endurance training such as running a certain distance or completing a challenging hike, is important. Create a plan that gradually increases the intensity and duration of your workouts. Incorporate both steady-state cardio and interval training in your plan. Make sure to include rest and recovery days in your training schedule. Incorporating strength training exercises such as squats, lunges, and deadlifts will improve your overall strength and reduce the risk of injury. To improve efficiency and prevent injury, focus on proper form and technique in all exercises. Proper hydration and nutrition is essential for endurance training, make sure to stay hydrated and fuel your body with nutrient-dense foods. Stay consistent and make adjustments as needed, progress takes time and patience.
                </div>

                <div className="d-flex justify-content-center mt-5 mb-5">
                    <img className="blog-img" src="https://ik.imagekit.io/deltafit/Assets/Blog_Endurance.jpg" alt="endurance"/>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
        </>
     );
}
 
export default DevelopmentEndurance;