import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../Styles/BlogPage.css"

const NewYearResolution = () => {
    return ( 
        <>
        <div className="blogread-bg">
        <Navbar/>
        <div className="container font-poppins blog-box-position">
            <div className="container text-white blog-box">
                <ul className="list-unstyled d-flex mt-5 mb-3">
                    <li>Author: Apoorva Raj</li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>Jan 6, 2020 </li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>1 min read</li>
                </ul>

                <div className="blog-heading mt-3 mb-3">
                Make those New Year's resolutions really last
                </div>

                <div className="mt-4 mb-4">
                Making New Year's resolutions is easy, but sticking to them can be a challenge. Here are a few tips to help you make your resolutions last:<br/><br/>
                <ol>
                <li>Be specific and make measurable goals. Instead of resolving to "lose weight," set a specific goal like "lose 10 pounds in the next three months." </li>
                <li>Break your goals into smaller, manageable steps. This will make it easier to stay on track and see progress.</li>
                <li>Create a plan of action. Identify the steps you need to take to achieve your goals and make a schedule for when you will work on them.</li>
                <li>Find accountability. Share your goals with a friend, family member, or coach, and ask them to check in on your progress.</li>
                <li>Reward yourself. Give yourself small rewards as you achieve milestones along the way.</li>
                <li>Be flexible. Life happens, and sometimes things don't go as planned. Don't get discouraged if you slip up, just get back on track as soon as possible.</li>
                <li>Stay positive and focus on the progress you've made. Don't get caught up in setbacks, celebrate the successes along the way.</li>
                <li>Don't give up. Stick with your goals, be persistent and don't give up even when things get tough. Remember, lasting change takes time and effort.</li>
                </ol>
                </div>

                <div className="d-flex justify-content-center mt-5 mb-5">
                    <img className="blog-img" src="https://ik.imagekit.io/deltafit/Assets/Blog_newyear.jpg" alt="endurance"/>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
        </>
     );
}
 
export default NewYearResolution;