import Navbar from "../components/Navbar";
import Banner from "../components/home/PromotionBanner"
import Footer from "../components/Footer";
import HeroImage from "../components/home/herosection";
import WhyUs from "../components/home/whyUs";
import Testimonials from "../components/home/testimonial";
import WhatweDo from "../components/home/whatwedo";
import ReadBlogs from "../components/home/readblogs";
import ContactUs from "../components/home/contactus";
import MotivationBanner from "../components/home/MotivationalBanner";
import "../Styles/HomePage.scss"

import { useState, useEffect } from "react";
import RotateLoader from "react-spinners/RotateLoader";

const Home=()=>{
  const [loading, setLoading] = useState(false);
        useEffect(()=>{
        setLoading(true)
        setTimeout(()=> {
          setLoading(false)
        })
      },[])
        
      return(
      
      <>
      {
       loading ? 
       <div className="d-flex align-items-center justify-content-center" style={{height:"100vh", background:"#0099AA"}} > 
        <RotateLoader
        color={"#001c37"}
        loading={loading}
        // cssOverride={override}
        size={15}
        speedMultiplier={0.5}
        aria-label="Loading Spinner"
        data-testid="loader"
      /></div> 
      :
      <>
        <Navbar/>
        <div className="responsive-navbar">   
                <HeroImage/>
                <Banner/>
                <WhyUs/>
                <WhatweDo/>
                <Testimonials/>
                <ReadBlogs/>
                <MotivationBanner/>
                <ContactUs/>
            <div className="container">
                {/* <Banner/> */}
            </div>
        </div>
        <Footer/>
        </>
      }
        </>
    )
}

export default Home;