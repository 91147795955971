import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const data = [
    {
        id: "1",
        username: "Shradhendra Kumar",
        position:"Source: Google",
        userimage:"https://ik.imagekit.io/deltafit/Assets/user_profile.png",
        testimonialHighlight:"Supportive, helpful & motivational",
        testimonial: "Coach Deepak is highly skilled in his profession, very supportive, helpful & motivation. I highly recommend to join this group if anyone wants to be fitness."
    },
    {
        id: "2",
        username: "Dushyant Nagar", 
        position:"Source: Google",
        userimage:"https://ik.imagekit.io/deltafit/Assets/user_profile.png",
        testimonialHighlight:" Thedeltafitness motive is #fitindia",
        testimonial: "Deepak is an Excellent trainer✌️. His knowledge about maintaining good health, sharing & communication is really helping out a lot to stay fit His motive is #FITINDIA."
    },
    {
        id: "3",
        username: "Abhinaw Paliwal", 
        position:"Source: Google",
        userimage:"https://ik.imagekit.io/deltafit/Assets/user_profile.png",
        testimonialHighlight:"Best training institute of Faridabad.",
        testimonial: "Delta fitness in one of the best training institute of Faridabad. Coach Deepak is very committed and passionate coach."
    },
    {
        id: "4",
        username: "Anjali Sangwan", 
        position:"Source: Google",
        userimage:"https://ik.imagekit.io/deltafit/Assets/user_profile.png",
        testimonialHighlight:"Awesome Workouts and environment",
        testimonial: "Awesome Workouts and very positive environment. Love to workout daily with delta fitness with full of energy."
    },
    {
        id: "5",
        username: "Atul Girdhar", 
        position:"Source: Google",
        userimage:"https://ik.imagekit.io/deltafit/Assets/user_profile.png",
        testimonialHighlight:"Overall & everything fitness center",
        testimonial: "Excellent place for fitness. Excellent coaching for athletes and marathon runners. Overall fitness center"
    },
    {
        id: "6",
        username: "Vibha Khosla", 
        position:"Source: Google",
        userimage:"https://ik.imagekit.io/deltafit/Assets/user_profile.png",
        testimonialHighlight:"One of the best fitness group",
        testimonial: "One of the best fitness group with lots of positive vibes. You get to learn a lot under the guidance of Coach Deepak."
    },
    {
        id: "7",
        username: "Sanchit Khosla", 
        position:"Source: Google",
        userimage: "https://ik.imagekit.io/deltafit/Assets/user_profile.png",
        testimonialHighlight:"Best training centre🔥🔥",
        testimonial: "One of those places where you just don't do workout but understand them. Best training centre🔥🔥"
    }
]

const Testimonials = () => {
    return ( 
    <>
    
    <div className='bg-testimonial-position'>
        <div className='bg-testimonials'>Testimonials</div>
    </div>
    
    <div className="testimonials-bg">
    <div className="text-center pt-5">
        <span className="text-center h3-testimonials">What our clients say is</span><br/><span className="text-center h4-testimonials">extremely important to us</span>
        </div>
        <div className='container-testimonials mt-4 pt-4'>
    <Swiper
      modules={[ Pagination, Mousewheel, Autoplay]}
      breakpoints={{
        0:{
            slidesPerView: 1,
            spaceBetween: 10,
        },
        480:{ 
            slidesPerView: 1,
            spaceBetween: 20,
        },
        576:{
            slidesPerView: 1,
            spaceBetween: 30,
        },
        768:{
            slidesPerView: 1,
            spaceBetween: 30,
        },
        992:{
            slidesPerView: 2,
            spaceBetween: 30,
        },
        1200:{
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1670:{
            slidesPerView: 4,
            spaceBetween: 0,
        },
      }}
      loop={true}
      mousewheel={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      pagination={{ clickable: true }}
    //   onSlideChange={() => console.log('slide change')}
    //   onSwiper={(swiper) => console.log(swiper)}
    >
      { data.map( user => (
        <SwiperSlide key={user.id}>
            <div className="testimonials-card" style={{marginBottom: "45px"}}>
                <div className='row' style={{marginRight:"38px", marginTop:"38px", marginLeft:"38px"}}>
                    <div className='w-25 testimonial-img'>
                        <img src={user.userimage} alt="profile-pic" style={{height: "60px", width:"60px", borderRadius:"50%"}}/>
                    </div>
                    <div className='w-75 testimonial-content'>

                    <div className="w-100" style={{marginTop:"15px"}}>
                            <span style={{fontFamily:"poppins", fontStyle:"normal", fontWeight:"600", fontSize:"18px", lineHeight:"22px", color:"#39364E"}}>{user.testimonialHighlight}</span>
                            <hr/>
                            <div className='w-100'>
                                <div style={{width:"fit-content", display:"inline"}}>
                                    <span style={{fontFamily:"poppins", fontStyle:"normal", fontWeight:"600", fontSize:"16px", lineHeight:"22px", color:"#571AF3"}}>{user.username}</span>
                                </div>
                                <div style={{width:"fit-content", display:"inline", marginLeft:"8px"}}>
                                <br/><span style={{fontFamily:"poppins", fontStyle:"normal", fontWeight:"500", fontSize:"15px", lineHeight:"22px", color:"#ACACAC", textAlign:"end"}}>{user.position}</span>
                                </div>
                            </div>
                        </div>

                        <div className='pt-2'><span style={{fontFamily:"poppins", fontStyle:"normal", fontWeight:"400", fontSize:"14px", lineHeight:"24px", color:"#696969"}}>{user.testimonial}</span></div>
                    </div>
                </div>
                
                
            </div>
        </SwiperSlide>
      ))}
    </Swiper>        
    </div>
    </div>
    <div className='slant-blue-bg-testimonial'></div>
    <div className='blue-bg-testimonial'></div>
    </> 
    );
}
 
export default Testimonials;