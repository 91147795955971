import { BrowserRouter,Route, Routes } from "react-router-dom";
import React, {useState, useEffect} from "react";
import RotateLoader from "react-spinners/RotateLoader";

import About from "./pages/About";
import Blog from "./pages/Blog";
import Gallary from "./pages/Gallary";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import DevelopmentTraining from "./pages/101-tips-for-starting-athletic-development-training"
import DevelopmentEndurance from "./pages/develop-endurance"
import NewYearResolution from "./pages/make-those-new-year-s-resolutions-really-last"
import BuildingGoodHabits from "./pages/building-good-habits";

function App(){
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    setLoading(true)
    setTimeout(()=> {
      setLoading(false)
    })
  },[])
  return(
    <>
    {
      loading ?
      <div className="d-flex align-items-center justify-content-center" style={{height:"100vh", background:"#0099AA"}} >
        
        <RotateLoader
        color={"#001c37"}
        loading={loading}
        // cssOverride={override}
        size={15}
        speedMultiplier={0.5}
        aria-label="Loading Spinner"
        data-testid="loader"
      /></div>
      :
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home/>}  />
              <Route path="/contact" element={<Contact/>} />  
              <Route path="/blog" element={<Blog/>} />  
              <Route path="/gallery" element={<Gallary/>} />    
              <Route path="/about" element={<About/>} />
              <Route path="/services" element={<Services/>} />
              <Route path="/blog/101-tips-for-starting-athletic-development-training" element={<DevelopmentTraining/>}/>
              <Route path="/blog/develop-endurance" element={<DevelopmentEndurance/>}/>
              <Route path="/blog/make-those-new-year-s-resolutions-really-last" element={<NewYearResolution/>}/>
              <Route path="/blog/building-good-habits" element={<BuildingGoodHabits/>}/>
            </Routes>
          </BrowserRouter>

    }
        
        
    </>
  )
}

export default App;