import Navbar from "../components/Navbar";
import Images from "../components/GalleryPageMedia";
import Footer from "../components/Footer";
import "../Styles/GalleryPage.css"
// import ComingSoon from "../img/ComingSoon.jpg"

const Gallary=()=>{
    return(
        <>
            <Navbar/>
            <div className="container pad-on-mobview">
            <div className="row mt-5 mb-5 d-flex align-items-center">
                    <div className="col-lg-6 col-12">
                        <div className="font-poppins marathon-padding">
                            <h2 className="h2-marathon">Delta Fitness<br/><span style={{color:"#00284e"}}>Marathon 2.0</span></h2>
                            <h6>Gallery</h6>
                            <div className="d-flex mb-3 marathon-categories">
                            <div className="d-flex">
                            {
                                (() => {
                                    if(window.innerWidth<=1201) {
                                            return (
                                            <a href="https://www.facebook.com/Drunningclub/photos" className="nav-link" style={{marginRight:"8px"}}><u>View</u></a>
                                            )
                                        } else {
                                            return (
                                            <a href="https://www.facebook.com/Drunningclub/photos_albums" className="nav-link" style={{marginRight:"8px"}}><u>View</u></a>
                                            )
                                        }
                                })()  
                            }
                            <a href="https://drive.google.com/uc?export=download&id=1aEJeENmNOwYF5eSi1qYjtANeZK0CoKXI" className="nav-link" style={{marginLeft:"8px"}}><u>Download</u></a></div>
                            </div>
                        </div>
                    </div> 

                    <div className="col-lg-6 col-12 d-flex justify-content-center">
                        <video src="https://ik.imagekit.io/deltafit/Assets/marathon-vid.mp4" autoplay="true" loop="true" muted="true" className="marathon-banner-web"/>

                        <video src="https://ik.imagekit.io/deltafit/Assets/marathon-vid.mp4" autoplay="true" loop="true" muted="true" className="marathon-banner-mob"/>
                    </div>                   
            </div> 
            <Images/> 
            </div>
            <Footer/>

        </>
    )
}

export default Gallary;