import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

const MotivationBanner = () => {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
    <>
        <div className="mt-5"></div>
        <div className="bg-motivation-banner">
            <div className="d-flex justify-content-center" style={{marginTop:"2.5em"}}>
                <div data-aos="flip-up" className="motivational-thought text-center m-auto text-white" style={{paddingTop:"4em"}}>
                    "Nothing Great Was Ever Achieved Without <br/>Enthusiasm"
                </div>
            </div>
            
                <div className="d-flex">
                    <div className="w-50"></div>
                    <div data-aos="flip-down" className="Ralph-Waldo text-white">-Ralph Waldo Emerson</div>
                </div>
        </div>
        <div className="mb-5"></div>

    </>
    );
}
 
export default MotivationBanner;