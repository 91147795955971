// import { Link } from "react-router-dom";
import Logo from "../img/Delta-Fitness-Logo.jpg";
// import Telephone from "../img/telephone-image.jpg";
// import Mail from "../img/Vector.jpg";
// import Facbook from "../img/Facebook.jpg";
// import Instagram from "../img/instagram.jpg";
// import Twitter from "../img/Twitterjpg.jpg";
import "../Styles/Navbar.css";
import { useState } from "react";
import Hamburger from 'hamburger-react'
 

// const url = ()=>{
// if (window.innerWidth<=1201){
//   url = "/#training-mob";
// }else{
//   url = "/#training-web"
// }}

const Navbar = () => {
  // document.getElementByClassName("fa")[0].addEventListener("click",function(){
 
  //   document.getElementByClassName("nav-ul")[0].classList.toggle("mylinks");
  // });
  
const [showMediaIcons,setShowMediaIcons] =  useState(false)
  return (
    // console.log(url),
    <>
      <div className="nav-container">
        <header className="nav-header">
          <a href="/" className="left-nav-border  sameline nav-link">
            <span className="col-branding">
              <img className="nav-logo" src={Logo} alt="" />
            </span>
            <span className="col-branding">
              <h2 className="brand-name">DELTA FITNESS</h2>
              {/* <p className="tag-line">Your best choice for fitness</p> */}
            </span>
          </a>
          <div className="hamburger-menu sameline fa " onClick={() => setShowMediaIcons(!showMediaIcons)}><Hamburger size={22} /></div>
          <div className="mid-space">
          </div>
          <div className="right-nav-border">
            <div className="right-nav-container">
              <nav className= "nav-link">
                <ul className= {showMediaIcons?"nav-ul mylinks":"nav-ul"}>
                  
                {
                (() => {
                    if(window.innerWidth<=1201) {
                            return (
                              <li><a href="/#training-mob">Training</a></li>
                            )
                        } else {
                            return (
                              <li><a href="/#training-web">Training</a></li>
                            )
                        }
                })()  
              }  
                  <li><a href="/#blogs">Blogs</a></li>
                  <li><a href="/#about-us">About</a></li>
                  <li><a href="/gallery">Gallery</a></li>
                  <li><a href="/services">Services</a></li>
                  <a  href="/contact" className="btn-contact">
                    <li style={{color:"white", fontWeight:600}}>Contact</li>
                  </a>
                </ul>
              </nav>
            </div>
          </div>
        </header> 
      </div>
    </>
  );
};
 
 
 
 
 
 
export default Navbar;
