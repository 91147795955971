import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";
// import Hero_mage from "../../img/Assets/HeroImage.png"

const HeroImage = () => {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return (
        <>
        <div className="home-bg d-flex align-items-center text-white">
        <div className="container position-hero">
            <div className="row d-flex align-items-center">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12  font-poppins responsive-hero">
                <span className="h1-herosection">Running</span><br/><span className='h3-herosection'>For The Future Fitness</span>

                <div className="font-arial p-herosection">
                Our trainers have the expertise and distinctive approach required for athletes training to get going quickly. We directly assist each trainee in achieving their own athletic goals. We are here to help you achieve all of your health and fitness goals by offering guidance and assistance. We chose this unusual trend in athletic training because we want to hold ourselves and our Delta Fitness Members to a high standard in the fitness industry. We want them to set and smash personal records each and every day.
                </div>
            <a className="nav-link DF-Button-white" href="/#about-us">
                Learn More
            </a>

            </div>



        <div data-aos="fade-up" className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div className="deltafit-imagehero">
                <div className="deltafit-imagehero2">
                    <img className='main-img-deltafit' src="https://ik.imagekit.io/deltafit/Assets/HeroImage.png" alt='heroimg'/>
                </div>
            </div>

        </div>
        </div>
        </div>
        </div>
        </>
      );
}
 
export default HeroImage;