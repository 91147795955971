import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../Styles/BlogPage.css";

const BuildingGoodHabits = () => {
    return ( 
        <>
        <div className="blogread-bg">
        <Navbar/>
        <div className="container font-poppins blog-box-position">
            <div className="container text-white blog-box">
                <ul className="list-unstyled d-flex mt-5 mb-3">
                    <li>Author: Apoorva Raj</li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>Jan 6, 2020 </li>
                    <li style={{margin:"0 8px"}}>|</li>
                    <li>1 min read</li>
                </ul>

                <div className="blog-heading mt-3 mb-3">
                Building Good Habits
                </div>

                <div className="mt-4 mb-4">
                Building a habit is a gradual process that requires time and effort. To establish a new habit, it's important to start small, choose a simple and manageable behavior that can easily be incorporated into your daily routine. Consistency is key, try to perform the new behavior at the same time every day to make it easier to remember. To make the process smoother, find ways to make the behavior easy to do. Keep track of your progress, it will help you see your progress and stay motivated. Find a trigger that you already have in your daily routine, associate the new behavior with it. Reward yourself for completing the behavior, it will help reinforce the habit. Remember, building a habit takes time, be patient and don't get discouraged if you slip up, just get back on track as soon as possible.
                </div>

                <div className="d-flex justify-content-center mt-5 mb-5">
                    <img className="blog-img" src="https://ik.imagekit.io/deltafit/Assets/Blog_goodhabits.jpg" alt="101tips"/>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
        </>
     );
}
 
export default BuildingGoodHabits;