import { ImagesLinkSet1 } from "../assets/Imegeslink";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

const Images = () => {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return (
    
    <>
      <div
        /*className="ad-banner-styles"*/ style={{
          marginTop: "35px",
          marginBottom: "35px",
        }}
      >
        <div className="row">
          {ImagesLinkSet1.map((element) => {
            return (
              <div data-aos="fade-up"
                className="col-lg-4 gallery-grid"
                // style={{ padding: "0px" }}
                key={element}
              >
               
                <LazyLoadImage
                  src={element}
                //   width={400}
                //   height={400}
                  alt="Image Alt"
                  className=" media-gallery"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Images;
