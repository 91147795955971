import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

import {Link} from 'react-router-dom';
// import Blog_newyear from "../../img/Assets/Blog_newyear.jpg";
// import Blog_endurance from "../../img/Assets/Blog_Endurance.jpg";
// import Blog_goodhabits from "../../img/Assets/Blog_goodhabits.jpg";
// import Blog_101tips from "../../img/Assets/Blog_101tips.jpeg"

const data = [
    {
        id: "1",
        Topic: "Stamina Building",
        Title: "101 tips for starting athletic development training",
        Author: "Apoorva",
        blogLink: "/blog/101-tips-for-starting-athletic-development-training",
        bannerLink: "https://ik.imagekit.io/deltafit/Assets/Blog_101tips.jpeg"
    },

    {
        id: "2",
        Topic: "Lifestyle",
        Title: "Building Good Habits",
        Author: "Apoorva",
        blogLink: "/blog/building-good-habits",
        bannerLink: "https://ik.imagekit.io/deltafit/Assets/Blog_goodhabits.jpg"
    },

    {
        id: "3",
        Topic: "Stamina Building",
        Title: "Developing Endurance",
        Author: "Apoorva",
        blogLink: "/blog/develop-endurance",
        bannerLink: "https://ik.imagekit.io/deltafit/Assets/Blog_Endurance.jpg"
    },

    {
        id: "4",
        Topic: "Motivational",
        Title: "Make those New Year's resolutions really last",
        Author: "Apoorva",
        blogLink: "/blog/make-those-new-year-s-resolutions-really-last",
        bannerLink: "https://ik.imagekit.io/deltafit/Assets/Blog_newyear.jpg"
    }
]



const ReadBlogs = () => {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>
        <div className="bg-readblogs" id='blogs'>
        <div className="container text-white">
            <div className='bg-blogs-position'>
                <div  data-aos="fade-up" className='bg-blogs'>BLOGS</div>
            </div>
                <div  data-aos="fade-down" className="explore-our-blogs text-center">EXPLORE OUR BLOGS</div>

                
            <div className="row">
            
            {data.map(blogs => {
            return (
                <div className="col-lg-3 col-sm-6 col-12 /*d-flex justify-content-center*/ blog-card">
                    <div>
                        <img  data-aos="fade-up" className="blog-banner" src={blogs.bannerLink} alt="banner" style={{borderRadius:"24px", paddingBottom:"8px"}}/>
                    </div>

                    <div data-aos="fade-up" className="topic-BlogCard">
                        {blogs.Title}
                    </div>
                    <div data-aos="fade-up" className="title-BlogCard">
                        {blogs.Topic}
                    </div>

                    <div data-aos="fade-up" className="link-BlogCard">
                        <Link to={blogs.blogLink} className="nav-link" href="">Read Blog</Link>
                    </div>
                </div>
            );
        })}
            
            </div>
        </div>
        </div>
        </>
     );
}
 
export default ReadBlogs;