export const ImagesLinkSet1=[
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC03988.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC03490.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC03630.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC03474.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/360A2094.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC03577.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC02758.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC02756.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC03156.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/DSC03147.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/IMG_8800.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/IMG_8916.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/IMG_9323.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/360A2975.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/360A3201.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/360A3195.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/360A2752.jpg",
    "https://ik.imagekit.io/deltafit/Assets/gallerymedia/360A2762.jpg",
]

