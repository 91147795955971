import React from 'react'
// import ReactDOM from 'react-dom';
// import {Link} from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import "../Styles/Footer.css";

const Footer = () => {
  return (
    <>
    <div className="pt-4 mt-5 display-web /*pb-4 */">

    <div className="container-footer pb-4">
        <div className="row">
            <div className="col-lg-3">
                <img src="https://ik.imagekit.io/deltafit/Assets/delta_fit_logo.webp" alt="Delta_fit_logo"/>
            </div>

            <div className="col-lg-6 font-poppins">
                <div className="row text-white">
                    <section style={{height: "55px", position:"relative", padding:"16px"}} className='col-lg-5-Footer d-flex justify-content-center'>  
                        <a className="nav-link  align-items-center" aria-current="page" style={{fontWeight: "500", fontSize: "17px"}} href="/#training-web">
                            Training
                        </a></section>  

                    <section style={{height: "55px", position:"relative", padding:"16px"}} className='col-lg-5-Footer d-flex justify-content-center'>  
                        <a className="nav-link  align-items-center" aria-current="page" style={{fontWeight: "500", fontSize: "17px"}} href="/#blogs">
                            Blog
                        </a></section>

                    <section style={{height: "55px", position:"relative", padding:"16px"}} className='col-lg-5-Footer d-flex justify-content-center'>
                        <a className="nav-link  align-items-center" aria-current="page" style={{fontWeight: "500", fontSize: "17px"}} href="/#about-us">
                            About
                        </a></section>

                    <section style={{height: "55px", position:"relative", padding:"16px"}} className='col-lg-5-Footer d-flex justify-content-center'>
                        <a className="nav-link  align-items-center" aria-current="page" style={{fontWeight: "500", fontSize: "17px"}} href="/gallery">
                            Gallery
                        </a></section>

                    <section style={{height: "55px", position:"relative", padding:"16px"}} className='col-lg-5-Footer d-flex justify-content-center'>
                        <a className="nav-link  align-items-center" aria-current="page" style={{fontWeight: "500", fontSize: "17px"}} href="services">
                            services
                        </a></section>                 
                </div>
                
                {/* custom HR */}
                <div>
                    <div style={{width:"100%", height:"25px", overflow:"hidden"}}>
                        <div style={{width:"100%", height:"1px", background:"#988AAB", position:"relative", top:"7.5px"}}>  
                            <div style={{float:"left", width:"7px", height:"7px", background:"#988AAB", borderRadius:"50%", position:"relative", top:"-3px"}}>
                            </div>
                            <div style={{float:"right", width:"7px", height:"7px", background:"#988AAB", borderRadius:"50%", position: "relative", top: "-3px"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                
                <div className="footer-contact-icons text-center">
                    <div style={{ height: 28, width: 28, border: "1.2px solid", borderRadius: "20px", borderColor: "#988AAB" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>
                    </div>
                    <div style={{ height: 28, width: 28, border: "1.2px solid", borderRadius: "20px", borderColor: "#988AAB", marginTop: 20 }}>    
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                        </svg>
                    </div>
                </div>
                <div className="text-white footer-contact-info justify-content-center" style={{marginLeft:"32px"}}>
                    <a className="nav-link" href="tel:+919716055207">+91 971 605 5207</a>
                    <br/>
                    <a className="nav-link" href="mailto:godeltafit@gmail.com"> godeltafit@gmail.com</a>
                </div>
            </div>
           
            <div className="row">
                <div className="col-lg-3 text-white">
                    <div className="row">
                        <div className="col-lg-2">
                            <a href='https://www.facebook.com/Drunningclub'>
                                <SocialIcon network="facebook" style={{ height: 40, width: 40, border: "1.2px solid", borderRadius: "20px", borderColor: "#FFFF" }} fgColor="#FFFFFF" bgColor='#001c37'/>
                            </a>
                        </div>
                        <div className="col-lg-2">
                            <a href='https://in.pinterest.com/godeltafit/'>
                                <SocialIcon network="pinterest" style={{ height: 40, width: 40, border: "1.2px solid", borderRadius: "20px", borderColor: "#FFFF"  }} fgColor="#FFFFFF" bgColor='#001c37'/>
                            </a>
                        </div>
                        <div className="col-lg-2">
                            <a href='https://instagram.com/delta_fitness_india'>
                                <SocialIcon network="instagram" style={{ height: 40, width: 40, border: "1.5px solid", borderRadius: "20px", borderColor: "#FFFF" }} fgColor="#FFFFFF" bgColor='#001c37'/>
                            </a>
                        </div>
                        <div className="col-lg-6">
                            <a class="styles-contactus text-center nav-link" href='/contact'>Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-white d-flex text-white justify-content-center font-roboto" style={{paddingTop: "7px", paddingBottom: "8px"}}>
                <a href="https://goo.gl/maps/VqxXUAvraxzkr24R9" className='nav-link'> Gurugram - Faridabad </a>
                </div>
                {/* <div className="col-lg-3 text-white text-center">
                    Enter your email id
                </div> */}
            </div>
        </div>

    </div>
    </div>
            
            
            
            <div className='display-mob pt-4'>
                <div className='w-100 d-flex justify-content-center'>
                    <img src="https://ik.imagekit.io/deltafit/Assets/delta_fit_logo.webp" alt="Delta_fit_logo"/>
                </div>

                

                <div className='d-flex justify-content-center' style={{ paddingBottom: "0.3em", paddingTop: "0.5em"}}>
                    <a href='https://www.facebook.com/Drunningclub'>
                        <SocialIcon network="facebook" style={{ height: 40, width: 40, border: "1.2px solid", borderRadius: "20px", borderColor: "#FFFF" }} fgColor="#FFFFFF" bgColor='#001c37'/>
                    </a> 

                    <div style={{marginLeft:"1em", marginRight:"1em"}} >
                        <a href='https://in.pinterest.com/godeltafit/'>
                            <SocialIcon network="pinterest" style={{ height: 40, width: 40, border: "1.2px solid", borderRadius: "20px", borderColor: "#FFFF"  }} fgColor="#FFFFFF" bgColor='#001c37'/>
                        </a>
                    </div>
                    <a href='https://instagram.com/delta_fitness_india'>
                        <SocialIcon network="instagram" style={{ height: 40, width: 40, border: "1.5px solid", borderRadius: "20px", borderColor: "#FFFF" }} fgColor="#FFFFFF" bgColor='#001c37'/>
                    </a>

                </div>

                {/* custom hr */}
                <div className='d-flex justify-content-center'>
                    <div style={{width:"75%", height:"25px", overflow:"hidden"}}>
                        <div style={{width:"100%", height:"1px", background:"#988AAB", position:"relative", top:"7.5px"}}>  
                            <div style={{float:"left", width:"7px", height:"7px", background:"#988AAB", borderRadius:"50%", position:"relative", top:"-3px"}}>
                            </div>
                            <div style={{float:"right", width:"7px", height:"7px", background:"#988AAB", borderRadius:"50%", position: "relative", top: "-3px"}}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-white d-flex text-white justify-content-center font-roboto" style={{paddingTop: ".2em", paddingBottom: "0.2em", fontSize:"14px"}}>
                <a href="https://goo.gl/maps/VqxXUAvraxzkr24R9" className='nav-link'> Gurugram - Faridabad </a>
                </div>


                <div className='text-white d-flex justify-content-center' style={{paddingTop: "0.2em", paddingBottom: "1.2em"}}>
                    <div className='mob-footer-contact-icons'>
                        <div className='text-center' style={{ height: 28, width: 28, border: "1.2px solid", borderRadius: "20px", borderColor: "#988AAB", marginRight:"0.5em" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                            </svg>
                        </div> 
                    </div><a href="tel:+919716055207" className='d-flex align-items-center nav-link' style={{fontSize:"14px"}}>+91 9716 055 207</a>
                    
                    <div className='mob-footer-contact-icons'>
                        <div className='text-center' style={{ height: 28, width: 28, border: "1.2px solid", borderRadius: "20px", borderColor: "#988AAB", marginLeft:"1em", marginRight:"0.5em"}}>    
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                            </svg>
                        </div>
                    </div><a href="mailto:godeltafit@gmail.com" className='d-flex align-items-center nav-link' style={{fontSize:"14px"}}>godeltafit@gmail.com</a>
                </div>
            </div>
            <div style={{background:"white"}}>
            <div className='container text-center bg-white font-roboto' style={{fontWeight: "400", fontSize: "18px"}}>
                <div className='d-flex justify-content-center'>
                    <div className='d-flex justify-content-end right-reserverd-font' style={{paddingRight:"8px"}}>
                    All Rights Reserved. 
                    </div>
                    <div className='d-flex justify-content-start right-reserverd-font' style={{paddingLeft:"8px"}}>
                    ©2022 by Delta Fitness.
                    </div>
                    </div>
            </div></div>
    </>
  )
}

export default Footer