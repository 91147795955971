import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

const WhyUs = () => {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>
        
        <div className="container" id="about-us">
            <div data-aos="fade-up" className="row d-flex align-items-center">
                <div className="col-lg-6 col-12 margin-top-mob-view">
                    <span style={{fontFamily: "poppins", fontStyle:"normal", fontWeight:"bold", fontSize:"68px", lineHeight:"71px", letterSpacing:"-0.01em", color:"#0090AA" }}>Why </span><span style={{fontFamily: "poppins", fontStyle:"normal", fontWeight:"bold", fontSize:"68px", lineHeight:"71px", letterSpacing:"-0.01em", color:"#00284E"}}>Choose Us</span>
                
                <div style={{fontFamily: "poppins", fontStyle:"normal", fontWeight:"400", fontSize:"16px", lineHeight:"27px", letterSpacing:"0.02em", color:"#1D1D1D", paddingTop: "22px" }}>
                Delta Fitness is a fitness and training facility located in Greater Faridabad, founded in 2017. We provide 24x7 training and specialize in making both men and women into elite athletes. We also offer fitness classes such as yoga, fun activities and routine exercises to improve flexibility and concentration. The founder, Deepak Kapasia, has been providing fitness training since 2017 and is dedicated to helping clients achieve their fitness goals through running drills, strength training, and gym workouts are the major nerves where we guide you the best.
                </div>
            <div>
                <ul className="list-unstyled font-roboto" style={{paddingTop: "31px", fontWeight:"400", fontSize:"18px", color:"#1E266D"}}>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>Marathon Running.</span>
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>Weight Loss & Weight Management.</span>
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>Kids Training.</span>
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>Athletics Training.</span>
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>Personal Training.</span>
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>Weight Lifting & Sport Training.</span>
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>Speed, Strength, Endurance & Flexibility workouts.</span>
                    </li>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#49E188" class="bi bi-check-circle-fill" viewBox="0 0 19 19">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <span style={{marginLeft:"13px"}}>21 Days Fitness Plan.</span>
                    </li>
                </ul>
            </div>  
            </div>

            <div className="col-lg-6 col-12 whyus-visible-web">
                <div className="row">
                   
                    <div className="col-lg-6 col-6 col-why-us1">
                    <div>
                        <img src="https://ik.imagekit.io/deltafit/Assets/Dots.jpg" alt="bg-dots" className="bg-topdot-styles"/>
                    </div>
                        <div className="deltafit-perks-card perk-card-margin1" style={{backgroundColor:"#0090AA"}}>
                            <div className="container" style={{paddingTop:"34px"}}>
                                <div className="deltafit-perks-card-img"><img style={{height:"80px", width:"80px"}} src="https://ik.imagekit.io/deltafit/Assets/Skill_Development.png" alt="perks-icon"/></div>
                                <div className="mob-deltafit-perks-card-h1">Skill Development.</div>
                                <div className="mob-deltafit-perks-card-p">We work with you to improve your skills in the specific sport or activity you are interested in.</div>
                            </div>
                        </div>
                        <div className="deltafit-perks-card perk-card-margin1" style={{marginTop:"30px", backgroundColor:"#00284E"}}>
                        <div className="container" style={{paddingTop:"34px"}}>
                                <div className="deltafit-perks-card-img"><img style={{height:"80px", width:"80px"}} src="https://ik.imagekit.io/deltafit/Assets/Goal_Setting.png" alt="perks-icon"/></div>
                                <div className="mob-deltafit-perks-card-h1">Goal Setting.</div>
                                <div className="mob-deltafit-perks-card-p">We help you set realistic and achievable goals for your athletic development and create a plan to achieve those goals.</div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-6 col-6 col-why-us2">
                        <div className="deltafit-perks-card perk-card-margin2" style={{ backgroundColor:"#00284E"}}>
                            <div className="container" style={{paddingTop:"34px"}}>
                                <div className="deltafit-perks-card-img"><img style={{height:"80px", width:"80px"}} src="https://ik.imagekit.io/deltafit/Assets/Fun_Activities.gif" alt="perks-icon"/></div>
                                <div className="mob-deltafit-perks-card-h1">Fun activities.</div>
                                <div className="mob-deltafit-perks-card-p">Team sports, football, volleyball, cycling, running, and swimming to develop endurance, strength and camaraderie.</div>
                            </div>
                        </div>
                        <div className="deltafit-perks-card perk-card-margin2" style={{marginTop: "30px", backgroundColor:"#0090AA"}}>
                            <div className="container" style={{paddingTop:"34px"}}>
                                <div className="deltafit-perks-card-img"><img style={{height:"80px", width:"80px"}} src="https://ik.imagekit.io/deltafit/Assets/Monitoring_Progress.png" alt="perks-icon"/></div>
                                <div className="mob-deltafit-perks-card-h1">Monitoring Progress.</div>
                                <div className="mob-deltafit-perks-card-p"> We track your progress and provide regular feedback to you including physical assessments & skill tests.</div>
                            </div>
                        </div>
                        <div>
                        <img src="https://ik.imagekit.io/deltafit/Assets/Dots.jpg" alt="bg-dots" className="bg-bottomdot-styles"/>
                        </div>
                        <div className="bg-choose-us-position">    
                            <div className="bg-choose-us-font-style">
                                CHOOSE US
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container whyus-visible-mobile">
                <div className="row">
                    <div className="w-100 d-flex justify-content-center mob-perk-card-top">    
                        <div className="perk-card-mob mob-perkcard-marginright text-center" style={{background:"#00284E"}}>
                        <div className="deltafit-perks-card-h1">Skill Development.</div>
                                <div className="mob-deltafit-perks-card-p">We work with you to improve your skills in the specific sport or activity you are interested in.</div>
                        </div>
                        <div className="perk-card-mob mob-perkcard-marginleft text-center" style={{background:"#0090AA"}}>
                        <div className="deltafit-perks-card-h1">Goal Setting.</div>
                                <div className="mob-deltafit-perks-card-p">We help you set realistic and achievable goals for your athletic development and create a plan to achieve those goals.</div>
                        </div>
                    </div>
                    
                    <div className="w-100 d-flex justify-content-center mob-perk-card-bottom">
                        <div className="perk-card-mob mob-perkcard-marginright text-center"  style={{background:"#0090AA"}}>
                        <div className="deltafit-perks-card-h1">Fun activities.</div>
                                <div className="mob-deltafit-perks-card-p">Team sports, football, volleyball, cycling, running, and swimming to develop endurance, strength and camaraderie.</div>
                        </div>
                        <div className="perk-card-mob mob-perkcard-marginleft text-center" style={{background:"#00284E"}}>
                        <div className="deltafit-perks-card-h1">Monitoring Progress.</div>
                                <div className="mob-deltafit-perks-card-p">We track your progress and provide regular feedback to you including physical assessments & skill tests.</div>
                        </div>
                    </div>
                </div>
            </div>


            </div>
        </div>
        
        
        </>
     );
}
 
export default WhyUs;