import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactUs from "../components/home/contactus";


const Contact=()=>{
    return(
        <>
            <Navbar/>
            <div className="pb-5"></div>
            <ContactUs/>
            <Footer/>

        </>
    )
}

export default Contact;