import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";


const WhatweDo = () => {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>

        {/* webview */}
        <div className="training-webview" id="training-web" style={{paddingTop:"1rem"}}>
        <div>
            <div>
                <div className="sport-category-bg-green-1"></div>
                <div className="sport-category-bg-green-1-1"></div>
            </div>
            <div>
                <div className="sport-category-bg-blue-1"></div>
            </div>
            <div>
                <div className="sport-category-bg-green-2"></div>
            </div>
            <div>
                <div className="sport-category-bg-blue-2"></div>
                <div className="sport-category-bg-blue-2-2"></div>
            </div>
        </div>
        </div>
        
        <div className="training-webview">
        <div className="container" style={{marginTop: "6.25rem", marginBottom:"80px"}}>
            <div className="row d-flex align-items-center">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <img data-aos="fade-right" data-aos-anchor-placement="center-center" className="training-img-web" src="https://ik.imagekit.io/deltafit/Assets/Personal_training.jpg" alt="practice-img"></img>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <div data-aos="fade-left" data-aos-anchor-placement="center-center" className="sport-category-h3-blue"><span style={{color:"white"}}>PERSONAL </span>SESSION</div>
                    <div data-aos="fade-left" data-aos-anchor-placement="center-center" className="sport-category-p text-white">Our Personal training sessions are one-on-one sessions between our trainers and you, where the trainer designs and leads a workout program made from drills and lessons used by some elite athletes. The sessions may include strength training, cardio, flexibility, and nutrition coaching. The trainer provides guidance, motivation, and accountability to help you achieve your fitness objectives.</div>
                </div>
            </div>


            <div className="row d-flex align-items-center">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <div data-aos="fade-right" data-aos-anchor-placement="center-center" className="sport-category-h3-green"><span style={{color:"white"}}>GROUP </span>SESSION</div>
                    <div data-aos="fade-right" data-aos-anchor-placement="center-center" className="sport-category-p text-white">Group training sessions are workouts led by our trainer that involve a group of people, typically 4 to 8 people. Our trainers' design and lead a workout program that is suitable for the group as a whole and can adapt to the fitness level of each member. The group atmosphere can provide a fun and motivating environment for participants to push themselves harder than they would on their own.</div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <img data-aos="fade-left" data-aos-anchor-placement="center-center" className="training-img-web" src="https://ik.imagekit.io/deltafit/Assets/group_training.jpg" alt="practice-img"></img>
                </div>
            </div>

            <div className="row d-flex align-items-center" style={{marginTop:"8.75rem"}}>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <img data-aos="fade-right" data-aos-anchor-placement="center-center" className="training-img-web" src="https://ik.imagekit.io/deltafit/Assets/marathon_training.jpg" alt="practice-img"></img>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <div data-aos="fade-left" data-aos-anchor-placement="center-center" className="sport-category-h3-blue"><span style={{color:"white"}}>MARATHON </span>TRAINING</div>
                    <div data-aos="fade-left" data-aos-anchor-placement="center-center" className="sport-category-p text-white">Marathon training is a program of physical preparation for running a marathon race. It typically involves a gradual increase in mileage and intensity over several months, with the goal of building the stamina and endurance needed to complete the race. Our training plans usually include a mix of long slow distance runs, shorter faster runs, hill and speed work, and rest days. The training plans also include strength, flexibility and recovery work.</div>
                </div>
            </div>

            <div className="row d-flex align-items-center">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <div data-aos="fade-right" data-aos-anchor-placement="center-center" className="sport-category-h3-green"><span style={{color:"white"}}>KIDS </span>TRAINING</div>
                    <div data-aos="fade-right" data-aos-anchor-placement="center-center" className="sport-category-p text-white">We offer a comprehensive service to help parents and caregivers identify and nurture the athletic talents of their children. Our service includes Talent identification, Skill development, Goal setting, Monitoring progress, and Connecting with local teams and clubs. Our goal is to help children develop their athletic talents in a safe, fun and encouraging environment and to provide them with the support they need to reach their full potential.</div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 sport-category-negative-transform">
                    <img  data-aos="fade-left" data-aos-anchor-placement="center-center" className="training-img-web" src="https://ik.imagekit.io/deltafit/Assets/Children_training.jpg" alt="practice-img"></img>
                </div>
            </div>
        </div>
        </div>


        {/* mobview */}
        <div className="training-mobview" id="training-mob">
            <div className="position-bg-absolute">
                <div>
                    <div className="mob-sport-category-bg-green-1"></div>
                    <div className="mob-sport-category-bg-green-1-1"></div>
                </div>
                <div>
                    <div className="mob-sport-category-bg-blue-1"></div>
                </div>
                <div>
                    <div className="mob-sport-category-bg-green-2"></div>
                </div>
                <div>
                    <div className="mob-sport-category-bg-blue-2"></div>
                    <div className="mob-sport-category-bg-blue-2-2"></div>
                </div>
            </div>
        </div>


        <div className="training-mobview">
            <div className="container text-center">
                <div className="row ">
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <div className="sport-category-h3-blue mob-heading-wwd"><span style={{color:"white"}}>PERSONAL </span>SESSION</div>
                        <div className="sport-category-p text-white">Our Personal training sessions are one-on-one sessions between our trainers and you, where the trainer designs and leads a workout program made from drills and lessons used by some elite athletes. The sessions may include strength training, cardio, flexibility, and nutrition coaching. The trainer provides guidance, motivation, and accountability to help you achieve your fitness objectives.</div>
                    </div>
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <img data-aos="fade-up" className="training-img-mob" src="https://ik.imagekit.io/deltafit/Assets/Personal_training.jpg" alt="practice-img"></img>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <div className="sport-category-h3-green mob-heading-wwd"><span style={{color:"white"}}>GROUP </span>SESSION</div>
                        <div className="sport-category-p text-white">Group training sessions are workouts led by our trainer that involve a group of people, typically 4 to 8 people. Our trainers' design and lead a workout program that is suitable for the group as a whole and can adapt to the fitness level of each member. The group atmosphere can provide a fun and motivating environment for participants to push themselves harder than they would on their own.</div>
                    </div>
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <img  data-aos="fade-up" className="training-img-mob" src="https://ik.imagekit.io/deltafit/Assets/group_training.jpg" alt="practice-img"></img>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <div className="sport-category-h3-blue mob-heading-wwd"><span style={{color:"white"}}>KIDS </span>TRAINING</div>
                        <div className="sport-category-p text-white">This class offers a new and exciting approach to your sports training. I developed my Personal Training Sessions from drills and lessons used by some of the most elite athletes. I know that practice makes perfect, but there is no perfect… so keep practicing with me. Schedule a class today.</div>
                    </div>
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <img  data-aos="fade-up" className="training-img-mob" src="https://ik.imagekit.io/deltafit/Assets/Children_training.jpg" alt="practice-img"></img>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <div className="sport-category-h3-green mob-heading-wwd"><span style={{color:"white"}}>MARATHON </span>TRAINING</div>
                        <div className="sport-category-p text-white">Marathon training is a program of physical preparation for running a marathon race. It typically involves a gradual increase in mileage and intensity over several months, with the goal of building the stamina and endurance needed to complete the race. Our training plans usually include a mix of long slow distance runs, shorter faster runs, hill and speed work, and rest days. The training plans also include strength, flexibility and recovery work.</div>                    
                    </div>
                    <div className="col-lg-12 mob-sport-category-negative-transform">
                        <img  data-aos="fade-up" className="training-img-mob" src="https://ik.imagekit.io/deltafit/Assets/marathon_training.jpg" alt="practice-img"></img>
                    </div>
                </div>
            </div>
        </div>


        </>
     );
}
 
export default WhatweDo;