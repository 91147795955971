import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";
// import RegistrationTimer from "../../scripts/CountdownTImer";
// import bannerWeb from "../../img/Assets/marathon-banner.jpg";
// import bannerMob from "../../img/Assets/marathon-mob.png";

const Banner=()=>{
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return(
        <>
        <div>
            <div className="container">
                <div className="row mt-5 mb-5 d-flex align-items-center">
                    <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="col-lg-6 col-12 d-flex justify-content-center">
                        <video src="https://ik.imagekit.io/deltafit/Assets/marathon-vid.mp4" autoplay="true" loop="true" muted="true" className="marathon-banner-web"/>

                        <video src="https://ik.imagekit.io/deltafit/Assets/marathon-vid.mp4" autoplay="true" loop="true" muted="true" className="marathon-banner-mob"/>
                        {/* <img  src={bannerWeb} alt="marathon" className="marathon-banner-web"/>
                        <img src={bannerMob} alt="marathon" className="marathon-banner-mob"/> */}
                    </div>
                    
                    <div className="col-lg-6 col-12">
                        <div data-aos="fade-down" data-aos-anchor-placement="bottom-bottom" className="font-poppins marathon-padding">
                            <h3><span style={{color:"#0090aa"}}>Thank you Faridabad!</span><br/> For making</h3>
                            <h2 className="h2-marathon">Delta Fitness<br/><span style={{color:"#00284e"}}>Marathon 2.0</span></h2>  
                            <h4>A huge success</h4>                         
                            <div className="d-flex mb-3 marathon-categories">
                                <br/>
                                <br/>
                                <br/>
                            </div>
                            <i>You can view and Download your photos<br/></i>

                            <br/>
                            <div className="button-padding">
                                <a data-aos="fade-down" data-aos-anchor-placement="bottom-bottom" className="nav-link DF-Button-red" href="/gallery">
                                Here!
                            </a>
                            </div>

                        </div>


                        {/* <div data-aos="fade-down" data-aos-anchor-placement="bottom-bottom" className="marathon-padding">
                            <RegistrationTimer/>
                            
                        </div> */}
                        
                    </div>                    
                </div>
        </div>     
        </div>   
        </>
    )
};

export default Banner;
